//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: 20px 0 20px 0;
  list-style: none;
  text-transform: uppercase;
  font-size: 13px;
  > li {
    display: inline-block;

    + li:before {
      content: "\003e"; // Unicode space added since inline-block means non-collapsing white-space
  padding: 0 15px 0 5px;
      color: #000;
    }
  }

  > .active {
    color: @blue;
  }
}
