.home{
	h2{
		text-align: center;
		color: #000;
	}
}
.flex-home{
	overflow: hidden;
	min-height: 544px;
	.slides{
		li{
			min-height: 544px;
			text-transform: uppercase;
			
			.image{
				min-height: 544px;
				background-size: cover;
				background-position: center top;
				.inner{
					float: left;
					margin-top: 100px;
					text-align: center;
				}
			}
			opacity: 0;
		}	
		h1{
			font-size: 60px;
			font-weight: 700;
			margin:0;
		}
		p{
			font-size: 43px;
		}
		.btn{
			font-size: 23px;
			border: 1px solid #000;
			padding: 10px 24px;
			font-weight: 700;
			margin-top: 15px;
		}
	}
}

.box{
	h3{
		text-align: center;
	}
}
.box-one{
	display: table;
	margin: 0 auto;
	img{
		margin-bottom: 4px;
		.img-responsive(block);
	}
	@media (min-width: @screen-sm-min){
		display: block;
	}
	.row{
		> div{
			padding: 0;
			@media (min-width: @screen-sm-min){
				padding: 4px 2px;
			}
		}
	}
	.image{
		position: relative;
		img{
			margin-bottom: 4px;
			display: inline-block;
		}
		a{
			text-decoration: none;
			transition: 200ms all ease;
			&:hover{
				letter-spacing: 1px;
				transition: 200ms all ease;
			}
		}
		.inner{
			position: absolute;
			top: 20%;
			left:20%;
			transform: translate(-20%, -20%);
			text-transform: uppercase;
			h3{
				font-size: 56px;
				font-weight: 700;
				margin:0;
			}
			p{
				font-size: 20px;
				margin:0;
				line-height: normal;
				span{
					color: #ff0000;
				}
			}
			a{
				font-size: 17px;
			}

		}
		&.white{
			color: #FFF;
			a{
				color: #FFF;
			}
		}
		&.img1{

			h3{
				font-size: 56px;
				letter-spacing: 1px;
			}
			p{

			}
			.inner{
				text-align: center;
			}
		}
		&.img2{
			h3{
				font-size: 48px;
				letter-spacing: 1px;
			}
			p{
				font-size: 23px;
				font-weight: 600;
			}
			.inner{
				top: 50%;
				left: 70%;
				transform: translate(-30%, -50%);
			}

		}
		&.img3{
			h3{
				font-size: 46px;
				letter-spacing: 1px;
			}
			p{
				font-size: 40px;
				font-weight: 700;
			}
			.inner{
				top: 50%;
				left: 62%;
				transform: translate(-38%, -50%);
			}

		}
		&.img4{

			h3{
				font-size: 60px;
				font-style: italic;
				font-weight: 700;
				letter-spacing: 3px;
			}
			p{
				font-size: 21px;
			}
			.inner{
				top: 50%;
				left: 64%;
				transform: translate(-36%, -50%);
				min-width: 160px;
				text-align: center;
			}
		}
		&.img5{

			h3{
				font-size: 40px;
				font-weight: 600;
				letter-spacing: 3px;
				border: 1px solid #000;
				padding: 4px 12px;
				margin-bottom: 10px;
			}
			p{
				font-size: 23px;
				font-weight: 600;
			}
			.inner{

			}
		}
		&.img6{

			h3{
				font-size: 48px;
				font-weight: 600;
				letter-spacing: 4px;
			}
			p{
				font-size: 26px;
				font-weight: 700;
			}
			.inner{
				top: 50%;
				left: 64%;
				transform: translate(-36%, -50%);
				text-align: center;
			}
		}
	}
}

.box-two{
	color: #FFF;
	h3{
		color: #000;
	}
	.image{
		min-height: 240px;
		background-position: 60% top;
		background-repeat: no-repeat;
		background-size: cover;

		.inner{
			font-family: @font-family-serif;
			text-transform: uppercase;
			margin-top: 30px;
			height: 0;
			float: left;
			p{
				font-size: 38px;
				line-height: normal;
			}
			.number{
				font-size: 160px;
				position: relative;
				top: -40px;
			}
			.right{
				float: right;
			}
			.top{
				font-size: 96px;
				font-family: Helvetica, Arial, sans-serif;
				font-weight: 700;
				position: absolute;
				top: 0;
				line-height: 0.9;
			}
			.bottom{
				position: absolute;
				bottom: 0;
				line-height: 2.1;
				font-size: 38px;
			}
		}
	}
}

.box-three{
	.wrap{
		padding: 0;
		@media (min-width: @screen-sm-min){
			padding: 0 10px;
		}
	}
	.inner{
		border-bottom: 1px solid #000;
		padding-bottom: 12px;
		margin-bottom: 8px;
	}
	img{
		margin: 0 auto 20px auto;
	}
	a{
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
		display: inline-block;
	}
	p{
		margin: 5px 0 0 0;
	}
	span{
		font-size: 10px;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
}