.sale{
	h2{
		font-size: 48px;
		font-weight: 700;
		border-bottom: 1px solid #000;
		text-align: center;
		padding-bottom: 15px;
		text-transform: uppercase;
		margin-bottom: 60px;
		margin-top: 50px;
	}
	img{
		margin-bottom: 20px;
		.img-responsive();
	}
}