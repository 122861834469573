.banner{
	min-height: 344px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 60% top;
	text-transform: uppercase;
	position: relative;
	border-bottom: 1px solid #000;
	.inner{
		margin-top: 70px;
		margin-left: 0;
		text-align: center;
		float: left;
		@media (min-width: @screen-md-min){
			margin-left: 140px;
		}
		p{
			margin: 10px 0 10px 0;
		}
		span{
			font-size: 26px;
			letter-spacing: 8px;
			color: #FFF;
			font-weight: 300;
		}
		.btn{
			background-color: #826854;
			border-color: #826854;
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 0;
			padding: 8px 16px;
			margin-top: 20px;
		}
	}
	&.default{
		.inner{
			margin: 0;
			float: none;
			position: absolute;
			left:20px;
			right: 20px;
			top: 50%;
			transform: translate(0, -50%);
			border: 3px solid #FFF;
			box-shadow: 5px 5px 5px 0 #000;
			z-index: 8;
			@media (min-width: @screen-sm-min){
				transform: translate(-50%, -50%);
				left:50%;
				right: auto;
			}
		}
		h1{
			font-weight: 400;
			font-style: italic;
			color: #FFF;
			
			padding: 18px 15px;
			font-size: 36px;
			background: rgba(0, 0, 0, 0.5);
			margin: 0;
			z-index: -1;
			position: relative;
			box-shadow: inset 5px 5px 5px 0 #000;
			text-shadow: 5px 5px 5px  #000;
			@media (min-width: @screen-xs-min){
				font-size: 58px;
				padding: 18px 54px;
			}
		}
	}
	&.cms{
		background: url('../img/banner_cms.png');
		min-height: 130px;
		margin-bottom: 50px;
		.inner{
			margin: 0;
			float: none;
			position: absolute;
			left:20px;
			right: 20px;
			top: 50%;
			transform: translate(0, -50%);
			border: 3px solid #FFF;
			box-shadow: 5px 5px 5px 0 #000;
			z-index: 8;
			margin-top: -4px;
			@media (min-width: @screen-sm-min){
				transform: translate(-50%, -50%);
				left:50%;
				right: auto;
			}
		}
		h1{
			font-weight: 400;
			font-style: italic;
			color: #FFF;
			padding: 18px 54px;
			font-size: 58px;
			background: rgba(0, 0, 0, 0.5);
			margin: 0;
			z-index: -1;
			position: relative;
			box-shadow: inset 5px 5px 5px 0 #000;
			text-shadow: 5px 5px 5px  #000;
		}
	}
	&.mens{
		span{
			color: #FFF;
		}
		.btn{
			background-color: #826854;
			border-color: #826854;
		}
	}
	&.womens{
		span{
			color: #000;
		}
		.btn{
			background-color: #000;
			border-color: #000;
		}
	}
}