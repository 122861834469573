.my-account{
	padding: 20px 0 70px 0;
	font-size: 20px;
	font-weight: 500;
	.left{
		ul{
			li{
				a{
					font-size: 20px;
					text-transform: uppercase;
					font-weight: 700;
					display: inline-block;
					margin-bottom: 40px;
				}
			}
		}
	}
	h3{
		font-weight: 500;
		text-transform: uppercase;
		font-size: 20px;
		margin-bottom: 15px;
		margin-top: 0;
	}
	hr{
		border-top-color: #000;
		margin-top: 0;
		margin-bottom: 30px;
	}
	label{
		font-weight: 500;
		text-transform: uppercase;
		font-size: 20px;
	}
	.table-responsive{
		padding-left: 40px;
	}
	.add{
		position: relative;
		border: none;
		padding: 0;
		a{
			position: absolute;
			bottom: 0;
			right: 0;
			font-size: 14px;
			padding: 10px 20px;
		}
	}

}
.table-payment{
	tr{
		td:first-child{
			text-align: left;
		}
		th:first-child{
			text-align: left;
		}
	}
	tbody{
		tr{
			td{
				padding: 24px 8px;
				font-size: 20px;
				font-weight: 400;
				label{
					text-transform: none;
				}
			}
		}
	}
}