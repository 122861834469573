.product{
	.flex-product{
		img{
			border: 1px solid #000;
		}
		#slider{
			margin-bottom: 30px;
		}
		#carousel{
			li{
				margin-right: 30px;
			}
		}
		.img-zoom{
			background: url('../img/img-zoom.png') no-repeat left top;
			padding-left: 30px;
			text-transform: uppercase;
			padding: 2px 0 2px 30px;
			font-size: 15px;
			font-weight: 500;
			position: relative;
			margin-top: -30px;
			display: block;
			margin-left: 10px;
			position: absolute;
			bottom: 0;
			&:hover, &:focus{
				text-decoration: none;
			}
		}
	}
	h1{
		font-size: 47px;
		color: @blue;
		text-transform: uppercase;
		margin-top: 0;
	}
	h3{
		font-size: 30px;
		color: @blue;
		margin: 20px 0 10px 0;
		text-transform: uppercase;
	}
	.price{
		font-size: 41px;
		font-weight: 700;
		font-style: italic;
	}
	.brand{
		font-size: 32px;
		text-transform: uppercase;
		margin: 0;
	}
	.item-n{
		font-size: 20px;
		margin: 0;
	}
	dl{
		font-size: 20px;
		text-transform: uppercase;
		margin-bottom: 5px;
		dt{
			text-align: left;
			font-weight: 400;
			padding: 2px 0;
			float: left;
			width: 160px;
			clear: left;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		dd{
			margin-left: 180px;
		}
	}
	.ships{
		font-size: 17px;
	}
	.btn-cart{
		font-size: 32px;
		font-weight: 700;
		padding: 3px 20px;
		margin-top: 15px;
		@media (min-width: @screen-md-min){
			font-size: 38px;
		}
	}
	.help{
		border: 1px solid #000;
		padding: 12px 0 12px 26px;
		font-size: 18px;
		margin-top: 30px;
		
		margin-bottom: 10px;
		@media (min-width: @screen-md-min){
			padding: 12px 102px 12px 26px;
			float: left;
		}
		a{
			padding: 3px 0;
			display: block;
			&:hover, &:focus{
				opacity: 0.6;
				text-decoration: none;
			}
		}
		img{
			margin-right: 10px;
		}
	}
	.social{
		li{
			padding-left: 2px;
			padding-right: 2px;
			a{
				width: 32px;
				height: 32px;
				display: inline-block;
				background-color: #000;
				background-position: left center;
				background-repeat: no-repeat;
				background-image: url('../img/i-social.png');
				&:hover, &:focus{
					opacity: 0.8;
				}
			}
			+ li{
				a{
					background-position: -32px center;
				}
			}
			+ li + li{
				a{
					background-position: -64px center;
				}
			}
			+ li + li + li{
				a{
					background-position: -96px center;
				}
			}
			+ li + li + li + li{
				a{
					background-position: -128px center;
				}
			}
			+ li + li + li + li + li{
				a{
					background-position: -160px center;
				}
			}
		}
	}
	.right{
		padding-top: 50px;
	}
	.nav-tabs{
		margin-top: 20px;
		li{
			a{
				font-weight: 700;
				font-size: 27px;
				letter-spacing: 2px;
				text-transform: uppercase;
				opacity: 0.6;
				border-bottom: 1px solid #000;
				margin-right: -1px;
				border-top: 10px solid #FFF;
				@media (min-width: @screen-sm-min){
					font-size: 21px;
					letter-spacing: 0px;
				}
				@media (min-width: @screen-md-min){
					font-size: 27px;
				}
				@media (min-width: @screen-lg-min){
					font-size: 30px;
				}
			}
			&.active{
				a{
					border: 1px solid #000;
					border-top: 10px solid @blue;
					opacity: 1;
					border-bottom-color: #000;
					@media (min-width: @screen-sm-min){
						border-bottom-color: #FFF;
					}
					&:hover, &:focus{
						border: 1px solid #000;
						border-top: 10px solid @blue;
						opacity: 1;
						border-bottom-color: #000;
						@media (min-width: @screen-sm-min){
							border-bottom-color: #FFF;
						}
					}
				}
			}
		}
	}
	.tab-content{
		border: 1px solid #000;
		padding: 40px 40px;
		border-top: none;
		font-size: 18px;
		margin-bottom: 50px;
		margin-right: -1px;
		margin-top: -4px;
		@media (min-width: @screen-sm-min){
			margin-top: 0;
		}
	}
	hr{
		border-color: #000;
		margin-top: 0;
	}
}


.similar{
	.flex-similar{
		li{
			margin-right: 10px;
			text-align: center;
			transition: 200ms all ease;
			&:hover, &:focus{
				opacity: 0.8;
			}
			a{
				&:hover, &:focus{
					text-decoration: none;
				}
			}
			h5{
				text-transform: uppercase;
				font-size: 16px;
				font-weight: 700;
				margin-bottom: 2px;
			}
			span{
				font-size: 12px;
				display: inline-block;
				line-height: 1.7;
			}
			p{
				margin: 0;
				font-size: 12px;
				font-weight: 700;
			}
		}
		.flex-viewport{
			margin: 0 60px;
		}
		.flex-direction-nav{
			a{
				opacity: 1;
				width: 48px;
				height: 48px;
				border: 1px solid #000;
				text-align: center;
				line-height: 1.2;
				&:before{
					content: '\003c';
					font-size: 30px;
					font-family: @font-family-sans-serif;
				}
			}
			.flex-prev{
				left: 0;
			}
			.flex-next{
				right:0;
				&:before{
					content: '\003e';
				}
			}
			.flex-disabled{
				opacity: 1 !important;
			}
		}
	}
}





/* =transition */

.mfp-ready .mfp-figure {
  opacity: 0;
}

.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */
}
.mfp-zoom-in .mfp-figure, .mfp-zoom-in .mfp-iframe-holder .mfp-iframe-scaler{
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
}
.mfp-zoom-in.mfp-bg,
.mfp-zoom-in .mfp-preloader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.mfp-zoom-in.mfp-image-loaded .mfp-figure, .mfp-zoom-in.mfp-ready .mfp-iframe-holder .mfp-iframe-scaler{
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.mfp-zoom-in.mfp-ready.mfp-bg,
.mfp-zoom-in.mfp-ready .mfp-preloader {
    opacity: 0.8;
}
.mfp-zoom-in.mfp-removing .mfp-figure, .mfp-zoom-in.mfp-removing .mfp-iframe-holder .mfp-iframe-scaler{
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0;
}
.mfp-zoom-in.mfp-removing.mfp-bg,
.mfp-zoom-in.mfp-removing .mfp-preloader {
    opacity: 0;
}
.mfp-iframe-scaler{ overflow: visible; /*so the close button is shown*/}
.mfp-zoom-out-cur { cursor: auto; }
.mfp-zoom-out-cur .mfp-image-holder .mfp-close { cursor: pointer; }