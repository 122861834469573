.news{
	.news-topic{
		li{
			&:first-child{
				a{
					cursor: text;
					&:hover,
					&:focus{
						background-color: #FFF;
					}
				}
			}
			a{
				&.active{
					font-weight: 700;
				}
			}
		}
	}
}