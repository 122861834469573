/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/


.flexslider {

  margin: 0; 
  padding: 0;

  .slides {

    > li {

      display: none; 
      -webkit-backface-visibility: hidden;

    }

    img{

      width: 100%;
      display: block;

    }


    //
    // Clearfix for the .slides element
    //

    &:after{

      content: "\0020"; 
      display: block; 
      clear: both; 
      visibility: hidden; 
      line-height: 0; 
      height: 0;


    }

    html[xmlns] &{
      
      display: block;

    }

    * html &{
      
      height: 1%;

    }


    
     // 
     // No JavaScript Fallback
     // If you are not using another script, such as Modernizr, make sure you
     // include js that eliminates this class on page load
     // 
    

    .no-js &{

      > li{

        &:first-child{

          display: block;

        }
      }
    }
  }
}