@charset "UTF-8";

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?1137807');
  src: url('../fonts/fontello.eot?1137807#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff?1137807') format('woff'),
       url('../fonts/fontello.ttf?1137807') format('truetype'),
       url('../fonts/fontello.svg?1137807#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

.fontello() {
  font-family: 'fontello'; 
  font-style: normal;
  speak: none;

  display: inline-block;
  width: 1.1em;
  margin-right: .1em;
  text-align: center;
}

.font-icon(@content, @size) {
  .fontello(); 
  font-size: @size; 
  content: @content;
}

.font-icon(@content) {
  .fontello();  
  content: @content;
}

[class^="icon-"], [class*=" icon-"] {
  .fontello();
}

.icon-facebook:before { content: '\e800'; } /* '' */
.icon-twitter:before { content: '\e801'; } /* '' */
.icon-instagramm:before { content: '\e802'; } /* '' */
.icon-gplus:before { content: '\e803'; } /* '' */
.icon-search:before { content: '\e804'; } /* '' */
.icon-pinterest-circled:before { content: '\e805'; } /* '' */
.icon-star:before { content: '\e806'; } /* '' */
.icon-help-circled-alt:before { content: '\e807'; } /* '' */
.icon-email:before { content: '\e808'; } /* '' */
.icon-zoom-in:before { content: '\e809'; } /* '' */
.icon-zoom-out:before { content: '\e80a'; } /* '' */

.icon-down-dir{
  font-size: 10px;
}