@font-face {
	font-family: 'big_johnregular';
	src: url('../fonts/big_john-webfont.eot');
	src: url('../fonts/big_john-webfont.eot?#iefix') format('embedded-opentype'),
	url('../fonts/big_john-webfont.woff2') format('woff2'),
	url('../fonts/big_john-webfont.woff') format('woff'),
	url('../fonts/big_john-webfont.ttf') format('truetype'),
	url('../fonts/big_john-webfont.svg#big_johnregular') format('svg');
	font-weight: normal;
	font-style: normal;

}
h2{
	font-size: 43px;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin: 60px 0 40px 0;
}
h4{
	font-size: 30px;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin: 20px 0 20px 0;
	color: @blue;
	font-weight: 700;
}