.category{
	.product{
		text-align: center;
		text-transform: uppercase;
		line-height: 1.6;

		.inner{
			padding: 15px;
			position: relative;
		}
		img{
			.img-responsive();
		}
		h2{
			font-size: 16px;
			margin: 0;
			height: 54px;
			overflow: hidden;
		}
		p{
			margin: 0;
			font-weight: 600;
			font-size: 12px;
		}
		.model{
			font-size: 12px;
		}
		.hover{
			opacity: 0;
			visibility: hidden;
			background-color: #b5c2d2;
			.rgba(#b5c2d2, 50%);
			background-color: @result;
			position: absolute;
			bottom: 0;
			left: 15px;
			right: 15px;
			transition: opacity .25s ease-in-out;
			.btn{
				font-size: 25px;
				color: #FFF;
				font-weight: 700;
				background-color: #959595;
				border-color:#959595;
				width: 100%;
				padding: 5px 10px;
				margin-bottom: 15px;
				transition: all .25s ease-in-out;
				display: none;
				@media (min-width: @screen-sm-min){
					font-size: 22px;
				}
				@media (min-width: @screen-lg-min){
					font-size: 25px;
				}
				&:hover{
					background-color: lighten(#959595, 10%);
					border-color:lighten(#959595, 10%);

				}
			}
		}
		&:hover .hover{
			position: absolute;
			opacity: 0;
			visibility: hidden;
			padding: 15px;
			left: 15px;
			right: 15px;
			bottom: 0;
			transition: opacity .25s ease-in-out;
			@media (min-width: @screen-sm-min){
				opacity: 1;
				visibility: visible;
			}
			.btn{
				display: block;
			}
		}
	}
}

.filters{
	border-bottom: 1px solid #000;
	.nav-pills {
		> li {
			> a{
				text-transform: uppercase;
				padding: 20px 18px;
				.caret {
					border-top: 5px dashed;
					border-right: 5px solid rgba(0, 0, 0, 0);
					border-left: 5px solid rgba(0, 0, 0, 0);
					position: relative;
					top: -1px;
				}
			}
		}
	}
	.dropdown-menu{
		margin: 0;

	}
}