footer{
	border-top: 1px solid #000;
	padding: 30px 0;
	text-transform: uppercase;
	margin-top: 50px;
	ul{
		li{
			a{
				padding: 0 45px 15px 0;
				display: inline-block;
			}
		}
	}
	p{
		font-size: 11px;
		margin-top: 10px;
	}
	.social{
		  text-align: center;
		li{
			  padding: 0 2px;
			a{
				background: #000;
				width: 32px;
				height: 32px;
				padding: 0;
				text-align: center;
				line-height: 1.8;
				&:hover{
					opacity: 0.8;
				}
				i{
					color: #FFF;
					font-size: 19px;
					width: auto;
					margin: 0;
				}
			}
		}
	}
}