.cart{
	h3{
		font-size: 23px;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 25px;
		@media (min-width: @screen-xs-min){
			font-size: 26px;
		}
	}
	.popover{
		min-width: 170px;
		margin-right: 20px;
	}
	.popover-title{
		font-size: 14px;
		margin-bottom: 0;
	}
	h4{
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: 10px;
		margin-bottom: 15px;
		color: #000;
	}
	.link-bottom{
		padding: 19px 0;
	}
	.table-cart{
		text-transform: uppercase;
		font-size: 20px;
		th, td{
			border:none;
			padding: 4px 0;
		}
		th{
			text-align: left;
			font-weight: 400;
			small{
				display: block;
				font-size: 12px;
			}
		}
		td{
			text-align: right;

		}
		h3{
			margin-top: 20px;
		}
	}
	.table-checkout{
		font-size: 16px;
		th, td{
			vertical-align: top;
			font-weight: 700;
			padding: 8px 0;
		}
	}
	ul{
		margin: 30px 0;
		li{
			font-size: 20px;
			text-transform: uppercase;
			font-weight: 700;
			padding: 6px 0;
		}
	}
	hr{
		border-top: 1px solid #000;
		margin: -10px 0 40px 0;
	}
	p{
		font-size: 24px;
		font-weight: 700;
		text-align: center;
		margin: 15px 0;
	}
	.cart-bottom{
		> div{

			@media (min-width: @screen-lg-min){
				padding: 0 20px;
			}
		}
	}
	.ccv{
		position: absolute;
		top: 16px;
		right: 30px;
		font-size: 22px;
		@media (min-width: @screen-lg-min){
			right: -10px;
		}
	}
	.checkout-products{
		font-size: 16px;
		font-weight: 700;
		text-transform: uppercase;
		small{
			display: block;
			font-size: 12px;
		}
		.row{
			> div{
				padding-bottom: 15px;
			}
		}
	}
}