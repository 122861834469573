//
// Close icons
// --------------------------------------------------


.close {
  float: right;
  font-size: 36px;
  font-weight: 400;
  line-height: 1;
  color: @close-color;
  text-shadow: @close-text-shadow;
  opacity:0.4;


  &:hover,
  &:focus {
    color: @close-color;
    text-decoration: none;
    cursor: pointer;
    opacity:0.8;
  }

  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  // See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
  button& {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}
