.top-bar{
	text-transform: uppercase;
	border-bottom: 1px solid #000;
	padding: 8px 0;
	li{
		line-height: 2.5;
	}
	.cart{
		background: url('../img/i-cart.png') no-repeat left 50%;
		padding: 0 0 0 26px;
		margin: 0 10px;
		margin-right: 50px;
	}
	.search{
		float: right;
	}
	.i-chat{
		background: url('../img/i-chat.png') no-repeat left 50%;
		padding: 6px 0 6px 50px;
	}
	.container{
		position: relative;
	}
	form.search {
		.search-wrap{
			position: absolute;
			right: 47px;
			top: 2px;
			min-width: 250px;
			display: none;
			button{
				position: absolute;
				top: 0;
				right: -32px;
				padding: 0;
				border: none;
			}
		}
	}
}
.searchbox{
	position:absolute;
	min-width:32px;
	width:0%;
	height:32px;
	overflow:hidden;
	right: 10px;
	transition: width 0.3s;
}

.searchbox-input{
	top:0;
	right:0;
	border:0;
	outline:0;
	width:100%;
	height:32px;
	margin:0;
	padding: 0px 55px 0px 12px;
	background: #EFEFEF;
	font-size: 15px;
	.placeholder(#000, 15px, 400, italic, none);
}

.searchbox-icon,
.searchbox-submit{
	width:32px;
	height:32px;
	display:block;
	position:absolute;
	top:0;
	right:0;
	padding:0;
	margin:0;
	border:0;
	outline:0;
	text-align:center;
	cursor:pointer;
	background: #000;
	color: #FFF;
}
.searchbox-open{
	width:286px;
}